/* tslint:disable */
/* eslint-disable */
/**
 * Cloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMailingChannelDto } from '../models';
// @ts-ignore
import { CreateMailingListDto } from '../models';
// @ts-ignore
import { CreateMailingListEntryDto } from '../models';
// @ts-ignore
import { MailingChannelDto } from '../models';
// @ts-ignore
import { MailingChannelPageDto } from '../models';
// @ts-ignore
import { MailingChannelSearchResultPageDto } from '../models';
// @ts-ignore
import { MailingDeliverableDto } from '../models';
// @ts-ignore
import { MailingDeliverablePageDto } from '../models';
// @ts-ignore
import { MailingDeliverableSearchResultPageDto } from '../models';
// @ts-ignore
import { MailingListDto } from '../models';
// @ts-ignore
import { MailingListPageDto } from '../models';
// @ts-ignore
import { MailingListSearchResultPageDto } from '../models';
// @ts-ignore
import { OptInOutConfirmationBodyDto } from '../models';
// @ts-ignore
import { RemoveMailingListEntryDto } from '../models';
// @ts-ignore
import { SearchRequestDto } from '../models';
// @ts-ignore
import { SendMailingListContentDto } from '../models';
// @ts-ignore
import { TaskDto } from '../models';
/**
 * MailingApi - axios parameter creator
 * @export
 */
export const MailingApiAxiosParamCreator = function (axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateMailingListEntryDto} createMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToMailingList: async (id: string, createMailingListEntry: CreateMailingListEntryDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addToMailingList.');
            }
            // verify required parameter 'createMailingListEntry' is not null or undefined
            if (createMailingListEntry === null || createMailingListEntry === undefined) {
                throw new RequiredError('createMailingListEntry','Required parameter createMailingListEntry was null or undefined when calling addToMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createMailingListEntry !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createMailingListEntry !== undefined ? createMailingListEntry : {})
                : (createMailingListEntry || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmMailingOptIn: async (optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optInOutConfirmationBody' is not null or undefined
            if (optInOutConfirmationBody === null || optInOutConfirmationBody === undefined) {
                throw new RequiredError('optInOutConfirmationBody','Required parameter optInOutConfirmationBody was null or undefined when calling confirmMailingOptIn.');
            }
            const localVarPath = `/mailing/opt-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof optInOutConfirmationBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(optInOutConfirmationBody !== undefined ? optInOutConfirmationBody : {})
                : (optInOutConfirmationBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmMailingOptOut: async (optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optInOutConfirmationBody' is not null or undefined
            if (optInOutConfirmationBody === null || optInOutConfirmationBody === undefined) {
                throw new RequiredError('optInOutConfirmationBody','Required parameter optInOutConfirmationBody was null or undefined when calling confirmMailingOptOut.');
            }
            const localVarPath = `/mailing/opt-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof optInOutConfirmationBody !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(optInOutConfirmationBody !== undefined ? optInOutConfirmationBody : {})
                : (optInOutConfirmationBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMailingChannelDto} createMailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingChannel: async (createMailingChannel: CreateMailingChannelDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMailingChannel' is not null or undefined
            if (createMailingChannel === null || createMailingChannel === undefined) {
                throw new RequiredError('createMailingChannel','Required parameter createMailingChannel was null or undefined when calling createMailingChannel.');
            }
            const localVarPath = `/mailing/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createMailingChannel !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createMailingChannel !== undefined ? createMailingChannel : {})
                : (createMailingChannel || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingDeliverable: async (mailingDeliverable: MailingDeliverableDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailingDeliverable' is not null or undefined
            if (mailingDeliverable === null || mailingDeliverable === undefined) {
                throw new RequiredError('mailingDeliverable','Required parameter mailingDeliverable was null or undefined when calling createMailingDeliverable.');
            }
            const localVarPath = `/mailing/deliverables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingDeliverable !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingDeliverable !== undefined ? mailingDeliverable : {})
                : (mailingDeliverable || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMailingListDto} createMailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingList: async (createMailingList: CreateMailingListDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMailingList' is not null or undefined
            if (createMailingList === null || createMailingList === undefined) {
                throw new RequiredError('createMailingList','Required parameter createMailingList was null or undefined when calling createMailingList.');
            }
            const localVarPath = `/mailing/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createMailingList !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createMailingList !== undefined ? createMailingList : {})
                : (createMailingList || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingChannel: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMailingChannel.');
            }
            const localVarPath = `/mailing/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingDeliverable: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMailingDeliverable.');
            }
            const localVarPath = `/mailing/deliverables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingList: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingChannels: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyMailingChannels.');
            }
            const localVarPath = `/mailing/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingDeliverables: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyMailingDeliverables.');
            }
            const localVarPath = `/mailing/deliverables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingLists: async (ids: Array<string>, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling deleteManyMailingLists.');
            }
            const localVarPath = `/mailing/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannel: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMailingChannel.');
            }
            const localVarPath = `/mailing/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannels: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannelsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/channels/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverable: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMailingDeliverable.');
            }
            const localVarPath = `/mailing/deliverables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverables: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/deliverables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverablesCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/deliverables/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingList: async (id: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingLists: async (page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingListsCount: async (page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mailing/lists/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (withReplacements !== undefined) {
                localVarQueryParameter['withReplacements'] = withReplacements;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a MailingChannel exists.
         * @summary Check if MailingChannel exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingChannelExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mailingChannelExists.');
            }
            const localVarPath = `/mailing/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a MailingDeliverable exists.
         * @summary Check if MailingDeliverable exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingDeliverableExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mailingDeliverableExists.');
            }
            const localVarPath = `/mailing/deliverables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a very performant method for checking if a MailingList exists.
         * @summary Check if MailingList exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingListExists: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling mailingListExists.');
            }
            const localVarPath = `/mailing/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingChannel: async (id: string, mailingChannel: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchMailingChannel.');
            }
            // verify required parameter 'mailingChannel' is not null or undefined
            if (mailingChannel === null || mailingChannel === undefined) {
                throw new RequiredError('mailingChannel','Required parameter mailingChannel was null or undefined when calling patchMailingChannel.');
            }
            const localVarPath = `/mailing/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingChannel !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingChannel !== undefined ? mailingChannel : {})
                : (mailingChannel || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingDeliverable: async (id: string, mailingDeliverable: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchMailingDeliverable.');
            }
            // verify required parameter 'mailingDeliverable' is not null or undefined
            if (mailingDeliverable === null || mailingDeliverable === undefined) {
                throw new RequiredError('mailingDeliverable','Required parameter mailingDeliverable was null or undefined when calling patchMailingDeliverable.');
            }
            const localVarPath = `/mailing/deliverables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingDeliverable !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingDeliverable !== undefined ? mailingDeliverable : {})
                : (mailingDeliverable || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingList: async (id: string, mailingList: any, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchMailingList.');
            }
            // verify required parameter 'mailingList' is not null or undefined
            if (mailingList === null || mailingList === undefined) {
                throw new RequiredError('mailingList','Required parameter mailingList was null or undefined when calling patchMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingList !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingList !== undefined ? mailingList : {})
                : (mailingList || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingChannelSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performMailingChannelSearch.');
            }
            const localVarPath = `/mailing/channels/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingDeliverableSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performMailingDeliverableSearch.');
            }
            const localVarPath = `/mailing/deliverables/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingListSearch: async (searchRequest: SearchRequestDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling performMailingListSearch.');
            }
            const localVarPath = `/mailing/lists/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchRequest !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
                : (searchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingChannelDto} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingChannel: async (id: string, mailingChannel: MailingChannelDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putMailingChannel.');
            }
            // verify required parameter 'mailingChannel' is not null or undefined
            if (mailingChannel === null || mailingChannel === undefined) {
                throw new RequiredError('mailingChannel','Required parameter mailingChannel was null or undefined when calling putMailingChannel.');
            }
            const localVarPath = `/mailing/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingChannel !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingChannel !== undefined ? mailingChannel : {})
                : (mailingChannel || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingDeliverable: async (id: string, mailingDeliverable: MailingDeliverableDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putMailingDeliverable.');
            }
            // verify required parameter 'mailingDeliverable' is not null or undefined
            if (mailingDeliverable === null || mailingDeliverable === undefined) {
                throw new RequiredError('mailingDeliverable','Required parameter mailingDeliverable was null or undefined when calling putMailingDeliverable.');
            }
            const localVarPath = `/mailing/deliverables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingDeliverable !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingDeliverable !== undefined ? mailingDeliverable : {})
                : (mailingDeliverable || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingListDto} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingList: async (id: string, mailingList: MailingListDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putMailingList.');
            }
            // verify required parameter 'mailingList' is not null or undefined
            if (mailingList === null || mailingList === undefined) {
                throw new RequiredError('mailingList','Required parameter mailingList was null or undefined when calling putMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mailingList !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mailingList !== undefined ? mailingList : {})
                : (mailingList || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoveMailingListEntryDto} removeMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromMailingList: async (id: string, removeMailingListEntry: RemoveMailingListEntryDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeFromMailingList.');
            }
            // verify required parameter 'removeMailingListEntry' is not null or undefined
            if (removeMailingListEntry === null || removeMailingListEntry === undefined) {
                throw new RequiredError('removeMailingListEntry','Required parameter removeMailingListEntry was null or undefined when calling removeFromMailingList.');
            }
            const localVarPath = `/mailing/lists/{id}/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof removeMailingListEntry !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(removeMailingListEntry !== undefined ? removeMailingListEntry : {})
                : (removeMailingListEntry || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromMailingListConfirmation: async (id: string, token: string, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeFromMailingListConfirmation.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling removeFromMailingListConfirmation.');
            }
            const localVarPath = `/mailing/lists/{id}/confirm/{token}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SendMailingListContentDto} sendMailingListContent 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailingContent: async (id: string, sendMailingListContent: SendMailingListContentDto, translate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sendMailingContent.');
            }
            // verify required parameter 'sendMailingListContent' is not null or undefined
            if (sendMailingListContent === null || sendMailingListContent === undefined) {
                throw new RequiredError('sendMailingListContent','Required parameter sendMailingListContent was null or undefined when calling sendMailingContent.');
            }
            const localVarPath = `/mailing/lists/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (axiosClientConfiguration) {
                baseOptions = axiosClientConfiguration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (axiosClientConfiguration && axiosClientConfiguration.accessToken) {
                const accessToken = typeof axiosClientConfiguration.accessToken === 'function'
                    ? await axiosClientConfiguration.accessToken()
                    : await axiosClientConfiguration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (translate !== undefined) {
                localVarQueryParameter['translate'] = translate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendMailingListContent !== 'string';
            const needsSerialization = nonString && axiosClientConfiguration && axiosClientConfiguration.isJsonMime
                ? axiosClientConfiguration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendMailingListContent !== undefined ? sendMailingListContent : {})
                : (sendMailingListContent || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailingApi - functional programming interface
 * @export
 */
export const MailingApiFp = function(axiosClientConfiguration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateMailingListEntryDto} createMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToMailingList(id: string, createMailingListEntry: CreateMailingListEntryDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).addToMailingList(id, createMailingListEntry, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmMailingOptIn(optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).confirmMailingOptIn(optInOutConfirmationBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmMailingOptOut(optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).confirmMailingOptOut(optInOutConfirmationBody, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateMailingChannelDto} createMailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMailingChannel(createMailingChannel: CreateMailingChannelDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).createMailingChannel(createMailingChannel, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMailingDeliverable(mailingDeliverable: MailingDeliverableDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverableDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).createMailingDeliverable(mailingDeliverable, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateMailingListDto} createMailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMailingList(createMailingList: CreateMailingListDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).createMailingList(createMailingList, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailingChannel(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteMailingChannel(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailingDeliverable(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteMailingDeliverable(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailingList(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteMailingList(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMailingChannels(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteManyMailingChannels(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMailingDeliverables(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteManyMailingDeliverables(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManyMailingLists(ids: Array<string>, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).deleteManyMailingLists(ids, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingChannel(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingChannel(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingChannels(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelPageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingChannels(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingChannelsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingChannelsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingDeliverable(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverableDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingDeliverable(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingDeliverables(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverablePageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingDeliverables(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingDeliverablesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingDeliverablesCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingList(id: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingList(id, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingLists(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListPageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingLists(page, size, translate, withReplacements, sort, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailingListsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).getMailingListsCount(page, size, sort, search, withReplacements, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a MailingChannel exists.
         * @summary Check if MailingChannel exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailingChannelExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).mailingChannelExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a MailingDeliverable exists.
         * @summary Check if MailingDeliverable exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailingDeliverableExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).mailingDeliverableExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a very performant method for checking if a MailingList exists.
         * @summary Check if MailingList exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailingListExists(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).mailingListExists(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailingChannel(id: string, mailingChannel: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).patchMailingChannel(id, mailingChannel, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailingDeliverable(id: string, mailingDeliverable: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverableDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).patchMailingDeliverable(id, mailingDeliverable, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailingList(id: string, mailingList: any, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).patchMailingList(id, mailingList, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performMailingChannelSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelSearchResultPageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).performMailingChannelSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performMailingDeliverableSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverableSearchResultPageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).performMailingDeliverableSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performMailingListSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListSearchResultPageDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).performMailingListSearch(searchRequest, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingChannelDto} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailingChannel(id: string, mailingChannel: MailingChannelDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingChannelDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).putMailingChannel(id, mailingChannel, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailingDeliverable(id: string, mailingDeliverable: MailingDeliverableDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingDeliverableDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).putMailingDeliverable(id, mailingDeliverable, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingListDto} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailingList(id: string, mailingList: MailingListDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingListDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).putMailingList(id, mailingList, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoveMailingListEntryDto} removeMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromMailingList(id: string, removeMailingListEntry: RemoveMailingListEntryDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).removeFromMailingList(id, removeMailingListEntry, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromMailingListConfirmation(id: string, token: string, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).removeFromMailingListConfirmation(id, token, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SendMailingListContentDto} sendMailingListContent 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMailingContent(id: string, sendMailingListContent: SendMailingListContentDto, translate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await MailingApiAxiosParamCreator(axiosClientConfiguration).sendMailingContent(id, sendMailingListContent, translate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (axiosClientConfiguration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MailingApi - factory interface
 * @export
 */
export const MailingApiFactory = function (axiosClientConfiguration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateMailingListEntryDto} createMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToMailingList(id: string, createMailingListEntry: CreateMailingListEntryDto, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).addToMailingList(id, createMailingListEntry, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmMailingOptIn(optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).confirmMailingOptIn(optInOutConfirmationBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OptInOutConfirmationBodyDto} optInOutConfirmationBody 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmMailingOptOut(optInOutConfirmationBody: OptInOutConfirmationBodyDto, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).confirmMailingOptOut(optInOutConfirmationBody, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMailingChannelDto} createMailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingChannel(createMailingChannel: CreateMailingChannelDto, translate?: string, options?: any): AxiosPromise<MailingChannelDto> {
            return MailingApiFp(axiosClientConfiguration).createMailingChannel(createMailingChannel, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingDeliverable(mailingDeliverable: MailingDeliverableDto, translate?: string, options?: any): AxiosPromise<MailingDeliverableDto> {
            return MailingApiFp(axiosClientConfiguration).createMailingDeliverable(mailingDeliverable, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMailingListDto} createMailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMailingList(createMailingList: CreateMailingListDto, translate?: string, options?: any): AxiosPromise<MailingListDto> {
            return MailingApiFp(axiosClientConfiguration).createMailingList(createMailingList, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingChannel(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteMailingChannel(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingDeliverable(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteMailingDeliverable(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailingList(id: string, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteMailingList(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingChannels(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteManyMailingChannels(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingDeliverables(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteManyMailingDeliverables(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManyMailingLists(ids: Array<string>, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).deleteManyMailingLists(ids, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannel(id: string, translate?: string, options?: any): AxiosPromise<MailingChannelDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingChannel(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannels(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<MailingChannelPageDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingChannels(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingChannelsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return MailingApiFp(axiosClientConfiguration).getMailingChannelsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverable(id: string, translate?: string, options?: any): AxiosPromise<MailingDeliverableDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingDeliverable(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverables(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<MailingDeliverablePageDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingDeliverables(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingDeliverablesCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return MailingApiFp(axiosClientConfiguration).getMailingDeliverablesCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingList(id: string, translate?: string, options?: any): AxiosPromise<MailingListDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingList(id, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [translate] 
         * @param {boolean} [withReplacements] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingLists(page?: number, size?: number, translate?: string, withReplacements?: boolean, sort?: string, search?: string, options?: any): AxiosPromise<MailingListPageDto> {
            return MailingApiFp(axiosClientConfiguration).getMailingLists(page, size, translate, withReplacements, sort, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {boolean} [withReplacements] 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailingListsCount(page?: number, size?: number, sort?: string, search?: string, withReplacements?: boolean, translate?: string, options?: any): AxiosPromise<number> {
            return MailingApiFp(axiosClientConfiguration).getMailingListsCount(page, size, sort, search, withReplacements, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a MailingChannel exists.
         * @summary Check if MailingChannel exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingChannelExists(id: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).mailingChannelExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a MailingDeliverable exists.
         * @summary Check if MailingDeliverable exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingDeliverableExists(id: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).mailingDeliverableExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a very performant method for checking if a MailingList exists.
         * @summary Check if MailingList exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailingListExists(id: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).mailingListExists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingChannel(id: string, mailingChannel: any, translate?: string, options?: any): AxiosPromise<MailingChannelDto> {
            return MailingApiFp(axiosClientConfiguration).patchMailingChannel(id, mailingChannel, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingDeliverable(id: string, mailingDeliverable: any, translate?: string, options?: any): AxiosPromise<MailingDeliverableDto> {
            return MailingApiFp(axiosClientConfiguration).patchMailingDeliverable(id, mailingDeliverable, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailingList(id: string, mailingList: any, translate?: string, options?: any): AxiosPromise<MailingListDto> {
            return MailingApiFp(axiosClientConfiguration).patchMailingList(id, mailingList, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingChannelSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<MailingChannelSearchResultPageDto> {
            return MailingApiFp(axiosClientConfiguration).performMailingChannelSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingDeliverableSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<MailingDeliverableSearchResultPageDto> {
            return MailingApiFp(axiosClientConfiguration).performMailingDeliverableSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequest 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performMailingListSearch(searchRequest: SearchRequestDto, translate?: string, options?: any): AxiosPromise<MailingListSearchResultPageDto> {
            return MailingApiFp(axiosClientConfiguration).performMailingListSearch(searchRequest, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingChannelDto} mailingChannel 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingChannel(id: string, mailingChannel: MailingChannelDto, translate?: string, options?: any): AxiosPromise<MailingChannelDto> {
            return MailingApiFp(axiosClientConfiguration).putMailingChannel(id, mailingChannel, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingDeliverableDto} mailingDeliverable 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingDeliverable(id: string, mailingDeliverable: MailingDeliverableDto, translate?: string, options?: any): AxiosPromise<MailingDeliverableDto> {
            return MailingApiFp(axiosClientConfiguration).putMailingDeliverable(id, mailingDeliverable, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MailingListDto} mailingList 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailingList(id: string, mailingList: MailingListDto, translate?: string, options?: any): AxiosPromise<MailingListDto> {
            return MailingApiFp(axiosClientConfiguration).putMailingList(id, mailingList, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RemoveMailingListEntryDto} removeMailingListEntry 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromMailingList(id: string, removeMailingListEntry: RemoveMailingListEntryDto, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).removeFromMailingList(id, removeMailingListEntry, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromMailingListConfirmation(id: string, token: string, translate?: string, options?: any): AxiosPromise<void> {
            return MailingApiFp(axiosClientConfiguration).removeFromMailingListConfirmation(id, token, translate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SendMailingListContentDto} sendMailingListContent 
         * @param {string} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailingContent(id: string, sendMailingListContent: SendMailingListContentDto, translate?: string, options?: any): AxiosPromise<TaskDto> {
            return MailingApiFp(axiosClientConfiguration).sendMailingContent(id, sendMailingListContent, translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addToMailingList operation in MailingApi.
 * @export
 * @interface MailingApiAddToMailingListRequest
 */
export interface MailingApiAddToMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiAddToMailingList
     */
    readonly id: string

    /**
     * 
     * @type {CreateMailingListEntryDto}
     * @memberof MailingApiAddToMailingList
     */
    readonly createMailingListEntry: CreateMailingListEntryDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiAddToMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for confirmMailingOptIn operation in MailingApi.
 * @export
 * @interface MailingApiConfirmMailingOptInRequest
 */
export interface MailingApiConfirmMailingOptInRequest {
    /**
     * 
     * @type {OptInOutConfirmationBodyDto}
     * @memberof MailingApiConfirmMailingOptIn
     */
    readonly optInOutConfirmationBody: OptInOutConfirmationBodyDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiConfirmMailingOptIn
     */
    readonly translate?: string
}

/**
 * Request parameters for confirmMailingOptOut operation in MailingApi.
 * @export
 * @interface MailingApiConfirmMailingOptOutRequest
 */
export interface MailingApiConfirmMailingOptOutRequest {
    /**
     * 
     * @type {OptInOutConfirmationBodyDto}
     * @memberof MailingApiConfirmMailingOptOut
     */
    readonly optInOutConfirmationBody: OptInOutConfirmationBodyDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiConfirmMailingOptOut
     */
    readonly translate?: string
}

/**
 * Request parameters for createMailingChannel operation in MailingApi.
 * @export
 * @interface MailingApiCreateMailingChannelRequest
 */
export interface MailingApiCreateMailingChannelRequest {
    /**
     * 
     * @type {CreateMailingChannelDto}
     * @memberof MailingApiCreateMailingChannel
     */
    readonly createMailingChannel: CreateMailingChannelDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiCreateMailingChannel
     */
    readonly translate?: string
}

/**
 * Request parameters for createMailingDeliverable operation in MailingApi.
 * @export
 * @interface MailingApiCreateMailingDeliverableRequest
 */
export interface MailingApiCreateMailingDeliverableRequest {
    /**
     * 
     * @type {MailingDeliverableDto}
     * @memberof MailingApiCreateMailingDeliverable
     */
    readonly mailingDeliverable: MailingDeliverableDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiCreateMailingDeliverable
     */
    readonly translate?: string
}

/**
 * Request parameters for createMailingList operation in MailingApi.
 * @export
 * @interface MailingApiCreateMailingListRequest
 */
export interface MailingApiCreateMailingListRequest {
    /**
     * 
     * @type {CreateMailingListDto}
     * @memberof MailingApiCreateMailingList
     */
    readonly createMailingList: CreateMailingListDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiCreateMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteMailingChannel operation in MailingApi.
 * @export
 * @interface MailingApiDeleteMailingChannelRequest
 */
export interface MailingApiDeleteMailingChannelRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingChannel
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingChannel
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteMailingDeliverable operation in MailingApi.
 * @export
 * @interface MailingApiDeleteMailingDeliverableRequest
 */
export interface MailingApiDeleteMailingDeliverableRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingDeliverable
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingDeliverable
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteMailingList operation in MailingApi.
 * @export
 * @interface MailingApiDeleteMailingListRequest
 */
export interface MailingApiDeleteMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyMailingChannels operation in MailingApi.
 * @export
 * @interface MailingApiDeleteManyMailingChannelsRequest
 */
export interface MailingApiDeleteManyMailingChannelsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MailingApiDeleteManyMailingChannels
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteManyMailingChannels
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyMailingDeliverables operation in MailingApi.
 * @export
 * @interface MailingApiDeleteManyMailingDeliverablesRequest
 */
export interface MailingApiDeleteManyMailingDeliverablesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MailingApiDeleteManyMailingDeliverables
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteManyMailingDeliverables
     */
    readonly translate?: string
}

/**
 * Request parameters for deleteManyMailingLists operation in MailingApi.
 * @export
 * @interface MailingApiDeleteManyMailingListsRequest
 */
export interface MailingApiDeleteManyMailingListsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MailingApiDeleteManyMailingLists
     */
    readonly ids: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MailingApiDeleteManyMailingLists
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingChannel operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingChannelRequest
 */
export interface MailingApiGetMailingChannelRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannel
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannel
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingChannels operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingChannelsRequest
 */
export interface MailingApiGetMailingChannelsRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingChannels
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingChannels
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannels
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingChannels
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannels
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannels
     */
    readonly search?: string
}

/**
 * Request parameters for getMailingChannelsCount operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingChannelsCountRequest
 */
export interface MailingApiGetMailingChannelsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingChannelsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingDeliverable operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingDeliverableRequest
 */
export interface MailingApiGetMailingDeliverableRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverable
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverable
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingDeliverables operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingDeliverablesRequest
 */
export interface MailingApiGetMailingDeliverablesRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverables
     */
    readonly search?: string
}

/**
 * Request parameters for getMailingDeliverablesCount operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingDeliverablesCountRequest
 */
export interface MailingApiGetMailingDeliverablesCountRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingDeliverablesCount
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingList operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingListRequest
 */
export interface MailingApiGetMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for getMailingLists operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingListsRequest
 */
export interface MailingApiGetMailingListsRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingLists
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingLists
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingLists
     */
    readonly translate?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingLists
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingLists
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingLists
     */
    readonly search?: string
}

/**
 * Request parameters for getMailingListsCount operation in MailingApi.
 * @export
 * @interface MailingApiGetMailingListsCountRequest
 */
export interface MailingApiGetMailingListsCountRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly withReplacements?: boolean

    /**
     * 
     * @type {string}
     * @memberof MailingApiGetMailingListsCount
     */
    readonly translate?: string
}

/**
 * Request parameters for mailingChannelExists operation in MailingApi.
 * @export
 * @interface MailingApiMailingChannelExistsRequest
 */
export interface MailingApiMailingChannelExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiMailingChannelExists
     */
    readonly id: string
}

/**
 * Request parameters for mailingDeliverableExists operation in MailingApi.
 * @export
 * @interface MailingApiMailingDeliverableExistsRequest
 */
export interface MailingApiMailingDeliverableExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiMailingDeliverableExists
     */
    readonly id: string
}

/**
 * Request parameters for mailingListExists operation in MailingApi.
 * @export
 * @interface MailingApiMailingListExistsRequest
 */
export interface MailingApiMailingListExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiMailingListExists
     */
    readonly id: string
}

/**
 * Request parameters for patchMailingChannel operation in MailingApi.
 * @export
 * @interface MailingApiPatchMailingChannelRequest
 */
export interface MailingApiPatchMailingChannelRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingChannel
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof MailingApiPatchMailingChannel
     */
    readonly mailingChannel: any

    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingChannel
     */
    readonly translate?: string
}

/**
 * Request parameters for patchMailingDeliverable operation in MailingApi.
 * @export
 * @interface MailingApiPatchMailingDeliverableRequest
 */
export interface MailingApiPatchMailingDeliverableRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingDeliverable
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof MailingApiPatchMailingDeliverable
     */
    readonly mailingDeliverable: any

    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingDeliverable
     */
    readonly translate?: string
}

/**
 * Request parameters for patchMailingList operation in MailingApi.
 * @export
 * @interface MailingApiPatchMailingListRequest
 */
export interface MailingApiPatchMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingList
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof MailingApiPatchMailingList
     */
    readonly mailingList: any

    /**
     * 
     * @type {string}
     * @memberof MailingApiPatchMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for performMailingChannelSearch operation in MailingApi.
 * @export
 * @interface MailingApiPerformMailingChannelSearchRequest
 */
export interface MailingApiPerformMailingChannelSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof MailingApiPerformMailingChannelSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPerformMailingChannelSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performMailingDeliverableSearch operation in MailingApi.
 * @export
 * @interface MailingApiPerformMailingDeliverableSearchRequest
 */
export interface MailingApiPerformMailingDeliverableSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof MailingApiPerformMailingDeliverableSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPerformMailingDeliverableSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for performMailingListSearch operation in MailingApi.
 * @export
 * @interface MailingApiPerformMailingListSearchRequest
 */
export interface MailingApiPerformMailingListSearchRequest {
    /**
     * 
     * @type {SearchRequestDto}
     * @memberof MailingApiPerformMailingListSearch
     */
    readonly searchRequest: SearchRequestDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPerformMailingListSearch
     */
    readonly translate?: string
}

/**
 * Request parameters for putMailingChannel operation in MailingApi.
 * @export
 * @interface MailingApiPutMailingChannelRequest
 */
export interface MailingApiPutMailingChannelRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingChannel
     */
    readonly id: string

    /**
     * 
     * @type {MailingChannelDto}
     * @memberof MailingApiPutMailingChannel
     */
    readonly mailingChannel: MailingChannelDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingChannel
     */
    readonly translate?: string
}

/**
 * Request parameters for putMailingDeliverable operation in MailingApi.
 * @export
 * @interface MailingApiPutMailingDeliverableRequest
 */
export interface MailingApiPutMailingDeliverableRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingDeliverable
     */
    readonly id: string

    /**
     * 
     * @type {MailingDeliverableDto}
     * @memberof MailingApiPutMailingDeliverable
     */
    readonly mailingDeliverable: MailingDeliverableDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingDeliverable
     */
    readonly translate?: string
}

/**
 * Request parameters for putMailingList operation in MailingApi.
 * @export
 * @interface MailingApiPutMailingListRequest
 */
export interface MailingApiPutMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingList
     */
    readonly id: string

    /**
     * 
     * @type {MailingListDto}
     * @memberof MailingApiPutMailingList
     */
    readonly mailingList: MailingListDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiPutMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for removeFromMailingList operation in MailingApi.
 * @export
 * @interface MailingApiRemoveFromMailingListRequest
 */
export interface MailingApiRemoveFromMailingListRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiRemoveFromMailingList
     */
    readonly id: string

    /**
     * 
     * @type {RemoveMailingListEntryDto}
     * @memberof MailingApiRemoveFromMailingList
     */
    readonly removeMailingListEntry: RemoveMailingListEntryDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiRemoveFromMailingList
     */
    readonly translate?: string
}

/**
 * Request parameters for removeFromMailingListConfirmation operation in MailingApi.
 * @export
 * @interface MailingApiRemoveFromMailingListConfirmationRequest
 */
export interface MailingApiRemoveFromMailingListConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiRemoveFromMailingListConfirmation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiRemoveFromMailingListConfirmation
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof MailingApiRemoveFromMailingListConfirmation
     */
    readonly translate?: string
}

/**
 * Request parameters for sendMailingContent operation in MailingApi.
 * @export
 * @interface MailingApiSendMailingContentRequest
 */
export interface MailingApiSendMailingContentRequest {
    /**
     * 
     * @type {string}
     * @memberof MailingApiSendMailingContent
     */
    readonly id: string

    /**
     * 
     * @type {SendMailingListContentDto}
     * @memberof MailingApiSendMailingContent
     */
    readonly sendMailingListContent: SendMailingListContentDto

    /**
     * 
     * @type {string}
     * @memberof MailingApiSendMailingContent
     */
    readonly translate?: string
}

/**
 * MailingApi - object-oriented interface
 * @export
 * @class MailingApi
 * @extends {BaseAPI}
 */
export class MailingApi extends BaseAPI {
    /**
     * 
     * @param {MailingApiAddToMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public addToMailingList(requestParameters: MailingApiAddToMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).addToMailingList(requestParameters.id, requestParameters.createMailingListEntry, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiConfirmMailingOptInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public confirmMailingOptIn(requestParameters: MailingApiConfirmMailingOptInRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).confirmMailingOptIn(requestParameters.optInOutConfirmationBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiConfirmMailingOptOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public confirmMailingOptOut(requestParameters: MailingApiConfirmMailingOptOutRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).confirmMailingOptOut(requestParameters.optInOutConfirmationBody, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiCreateMailingChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public createMailingChannel(requestParameters: MailingApiCreateMailingChannelRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).createMailingChannel(requestParameters.createMailingChannel, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiCreateMailingDeliverableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public createMailingDeliverable(requestParameters: MailingApiCreateMailingDeliverableRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).createMailingDeliverable(requestParameters.mailingDeliverable, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiCreateMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public createMailingList(requestParameters: MailingApiCreateMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).createMailingList(requestParameters.createMailingList, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteMailingChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteMailingChannel(requestParameters: MailingApiDeleteMailingChannelRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteMailingChannel(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteMailingDeliverableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteMailingDeliverable(requestParameters: MailingApiDeleteMailingDeliverableRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteMailingDeliverable(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteMailingList(requestParameters: MailingApiDeleteMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteMailingList(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteManyMailingChannelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteManyMailingChannels(requestParameters: MailingApiDeleteManyMailingChannelsRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteManyMailingChannels(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteManyMailingDeliverablesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteManyMailingDeliverables(requestParameters: MailingApiDeleteManyMailingDeliverablesRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteManyMailingDeliverables(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiDeleteManyMailingListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public deleteManyMailingLists(requestParameters: MailingApiDeleteManyMailingListsRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).deleteManyMailingLists(requestParameters.ids, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingChannel(requestParameters: MailingApiGetMailingChannelRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingChannel(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingChannelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingChannels(requestParameters: MailingApiGetMailingChannelsRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingChannels(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingChannelsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingChannelsCount(requestParameters: MailingApiGetMailingChannelsCountRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingChannelsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingDeliverableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingDeliverable(requestParameters: MailingApiGetMailingDeliverableRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingDeliverable(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingDeliverablesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingDeliverables(requestParameters: MailingApiGetMailingDeliverablesRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingDeliverables(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingDeliverablesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingDeliverablesCount(requestParameters: MailingApiGetMailingDeliverablesCountRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingDeliverablesCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingList(requestParameters: MailingApiGetMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingList(requestParameters.id, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingLists(requestParameters: MailingApiGetMailingListsRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingLists(requestParameters.page, requestParameters.size, requestParameters.translate, requestParameters.withReplacements, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiGetMailingListsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public getMailingListsCount(requestParameters: MailingApiGetMailingListsCountRequest = {}, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).getMailingListsCount(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.search, requestParameters.withReplacements, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a MailingChannel exists.
     * @summary Check if MailingChannel exists
     * @param {MailingApiMailingChannelExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public mailingChannelExists(requestParameters: MailingApiMailingChannelExistsRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).mailingChannelExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a MailingDeliverable exists.
     * @summary Check if MailingDeliverable exists
     * @param {MailingApiMailingDeliverableExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public mailingDeliverableExists(requestParameters: MailingApiMailingDeliverableExistsRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).mailingDeliverableExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is a very performant method for checking if a MailingList exists.
     * @summary Check if MailingList exists
     * @param {MailingApiMailingListExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public mailingListExists(requestParameters: MailingApiMailingListExistsRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).mailingListExists(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPatchMailingChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public patchMailingChannel(requestParameters: MailingApiPatchMailingChannelRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).patchMailingChannel(requestParameters.id, requestParameters.mailingChannel, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPatchMailingDeliverableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public patchMailingDeliverable(requestParameters: MailingApiPatchMailingDeliverableRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).patchMailingDeliverable(requestParameters.id, requestParameters.mailingDeliverable, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPatchMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public patchMailingList(requestParameters: MailingApiPatchMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).patchMailingList(requestParameters.id, requestParameters.mailingList, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPerformMailingChannelSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public performMailingChannelSearch(requestParameters: MailingApiPerformMailingChannelSearchRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).performMailingChannelSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPerformMailingDeliverableSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public performMailingDeliverableSearch(requestParameters: MailingApiPerformMailingDeliverableSearchRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).performMailingDeliverableSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPerformMailingListSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public performMailingListSearch(requestParameters: MailingApiPerformMailingListSearchRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).performMailingListSearch(requestParameters.searchRequest, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPutMailingChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public putMailingChannel(requestParameters: MailingApiPutMailingChannelRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).putMailingChannel(requestParameters.id, requestParameters.mailingChannel, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPutMailingDeliverableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public putMailingDeliverable(requestParameters: MailingApiPutMailingDeliverableRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).putMailingDeliverable(requestParameters.id, requestParameters.mailingDeliverable, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiPutMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public putMailingList(requestParameters: MailingApiPutMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).putMailingList(requestParameters.id, requestParameters.mailingList, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiRemoveFromMailingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public removeFromMailingList(requestParameters: MailingApiRemoveFromMailingListRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).removeFromMailingList(requestParameters.id, requestParameters.removeMailingListEntry, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiRemoveFromMailingListConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public removeFromMailingListConfirmation(requestParameters: MailingApiRemoveFromMailingListConfirmationRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).removeFromMailingListConfirmation(requestParameters.id, requestParameters.token, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailingApiSendMailingContentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public sendMailingContent(requestParameters: MailingApiSendMailingContentRequest, options?: any) {
        return MailingApiFp(this.axiosClientConfiguration).sendMailingContent(requestParameters.id, requestParameters.sendMailingListContent, requestParameters.translate, options).then((request) => request(this.axios, this.basePath));
    }
}
