import { Injectable } from "@angular/core";
import { CreateMailingChannelDto, MailingApi, MailingChannelDto } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";
import { currentProjectId$ } from "../../../app.subscriptions";
import { ApiClientFactory } from "../../api-client/services/api-client.factory";

@Injectable({ providedIn: "root" })
export class MailingChannelStore extends PageableCrudStore<MailingChannelDto, CreateMailingChannelDto> {
  constructor(private apiClientFactory: ApiClientFactory) {
    super({ loadOnDeletion: true });
    void currentProjectId$.subscribe(() => this.reset());
  }

  protected async loadModelById(id: string): Promise<MailingChannelDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).getMailingChannel({ id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<any> {
    const res = await this.apiClientFactory.project().get(MailingApi).deleteMailingChannel({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.apiClientFactory.project().get(MailingApi).deleteManyMailingChannels({ ids });
    return res.data;
  }
  protected async createModel(createMailingChannel: CreateMailingChannelDto): Promise<MailingChannelDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).createMailingChannel({ createMailingChannel });
    return res.data;
  }
  protected async patchModel(id: string, mailingChannel: Partial<MailingChannelDto>): Promise<MailingChannelDto> {
    const res = await this.apiClientFactory.project().get(MailingApi).patchMailingChannel({ id, mailingChannel });
    return res.data;
  }
  protected async putModel(mailingChannel: MailingChannelDto): Promise<MailingChannelDto> {
    const res = await this.apiClientFactory
      .project()
      .get(MailingApi)
      .putMailingChannel({ id: mailingChannel.id, mailingChannel });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<MailingChannelDto>> {
    const res = await this.apiClientFactory.project().get(MailingApi).getMailingChannels(query);
    return res.data;
  }
}
